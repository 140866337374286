import React from "react";
import backgroundImage from "./retardation.gif";

function App() {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    paddingTop: "50px",
    paddingBottom: "50px",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)", // Add overlay for better text readability
    zIndex: 0,
  };

  const contentStyle = {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "white",
    fontWeight: "700",
  };

  const logoStyle = {
    width: window.innerWidth >= 768 ? "300px" : "200px", // Adjust logo size
    height: "auto",
    marginBottom: "20px",
  };

  const textStyle = {
    fontSize: window.innerWidth >= 768 ? "24px" : "16px", // Improved scaling for mobile
    marginBottom: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
    lineHeight: "1.4",
    color: "white",
    backgroundImage:
      "linear-gradient(176deg, orange 0%, #b958ee 51%, orange 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent", // Gradient effect
  };

  const buttonStyle = {
    backgroundColor: "#ff7f50", // Coral color
    color: "white",
    border: "none",
    padding: "10px 20px",
    margin: "10px",
    borderRadius: "30px", // Rounded buttons
    cursor: "pointer",
    transition: "background-color 0.3s",
    fontWeight: "bold",
  };

  const buttonHoverStyle = {
    backgroundColor: "#ff4500", // Darker on hover
  };

  const linkStyle = {
    color: "inherit",
    textDecoration: "none",
  };

  const descriptionStyle = {
    width: window.innerWidth >= 768 ? "500px" : "300px",
    fontSize: "16px",
    lineHeight: "1.6",
    marginTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <div style={overlayStyle}></div>
      <div style={contentStyle}>
        <img
          src={"https://i.imgur.com/Xtr88Gb.jpeg"}
          alt="logo"
          style={logoStyle}
        />
        <div style={textStyle}>SafeMajorRoseDelugeBuybotGuard (SOLAMI)</div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={buttonStyle}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = buttonHoverStyle.backgroundColor;
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = buttonStyle.backgroundColor;
            }}
          >
            <a href="https://t.me/SMRDBuyG" style={linkStyle}>
              Telegram
            </a>
          </button>

          <button
            style={buttonStyle}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = buttonHoverStyle.backgroundColor;
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = buttonStyle.backgroundColor;
            }}
          >
            <a href="https://x.com/SMRDBuyG" style={linkStyle}>
              Twitter
            </a>
          </button>
        </div>

        <div style={descriptionStyle}>
          <p>
            SafeMajorRoseDelugeBuybotGuard – the ultimate super-bot token,
            combining all your favorite bots into one unstoppable tripping
            alpha!
          </p>
        </div>
      </div>
    </div>
  ); //;njububub
}
//nbububyvyt

export default App;
